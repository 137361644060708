.App {
    /* background-color: #eeeee4; */
    min-height: 100vh;

    /* font-size: calc(50px + 2vmin); */
    color: #063970;
    /* font-family: "Archivo Black", sans-serif;
     */
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-style: normal;
}
.page {
    padding: 30px;
    z-index: 1;
}
.titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}
.titleHeader {
    container-type: inline-size;
    width: 100%;
}
h1 {
    font-size: 16cqw;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    line-height: 1em;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

h1:hover {
    font-weight: 700;
}

h2 {
    font-size: 5cqw;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    line-height: 1em;
    margin-block-end: 5px;
}

h2:hover {
    font-weight: 700;
}

h3 {
    font-size: 3cqw;
    font-weight: 300;
}
.link {
    padding: 10px;
    color: #eeeee4;
    background-color: #063970;
    font-size: 3cqw;
    font-weight: 300;
    margin-right: 20px;
    transition: all 0.2s ease-in-out;
}
.link:hover {
    font-weight: 700;
}
.projectContainer {
    margin: 10px 0;
}

.carouselContainer {
    width: 80%;
    margin-top: 30px;
    margin-bottom: 100px;
}

.carouselButton {
    /* background-color: #063970; */
    position: absolute;
    z-index: 2;
    top: 0px;
    width: 50px;
    height: 100%;
    cursor: pointer;
    backdrop-filter: blur(3px);
    /* background-color: none; */
}

.sketch {
    z-index: -1;
    position: fixed;
}
.resumeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
iframe {
    display: block; /* iframes are inline by default */
    border: none; /* Reset default border */
    height: auto; /* Viewport-relative units */
    aspect-ratio: 8.5/11;
    width: 80vw;
    margin-bottom: 200px;
    margin-top: 20px;
}

footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 50px;
    padding: 20px;
    backdrop-filter: blur(3px);
}

.footerContainer {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.9cqw;
    width: 90%;
}

i {
    margin-right: 3px;
    margin-left: 10px;
}

.footerText {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
}

.footerText:hover {
    transform: scale(1.05);
    font-weight: 700;
}

.carousel .thumb {
    border: none !important;
    transition: all 0.2s ease-in-out;
}
.carousel .thumb.selected {
    transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
    .name {
        font-size: 26cqw;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    .description {
        font-size: 5cqw;
    }

    footer {
        height: 100px;
    }
    .footerContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        font-size: 3.5cqw;
    }
    .carouselContainer {
        width: 100%;
    }
}
