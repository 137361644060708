ul {
    list-style-type: none;
    width: auto;
    margin-block-start: 0px;
    margin-block-end: 0px;
}
li {
    font-size: 5cqw;
    margin-block-start: 20px;
    margin-block-end: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 300;
    line-height: 1em;
}
a {
    color: #063970;
    text-decoration: none;
}
li:hover {
    transform: scale(1.05);
    font-weight: 700;
}

.active {
    font-weight: 700;
}
.navContainer {
    display: flex;
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    z-index: 3;
    position: relative;
}

.icon-menu {
    --gap: 10px;
    --height-bar: 10px;
    --pos-y-bar-one: 0;
    --pos-y-bar-three: 0;
    --scale-bar: 1;
    --rotate-bar-one: 0;
    --rotate-bar-three: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    cursor: pointer;
    position: relative;
}

.bar {
    position: relative;
    height: var(--height-bar);
    width: 100%;
    /* border-radius: .5rem; */
    background-color: #063970;
}

.bar--1 {
    top: var(--pos-y-bar-one);
    transform: rotate(var(--rotate-bar-one));
    transition: top 200ms 100ms, transform 100ms ease-in-out;
}

.bar--2 {
    transform: scaleX(var(--scale-bar));
    transition: transform 150ms 100ms ease-in-out;
}

.bar--3 {
    bottom: var(--pos-y-bar-three);
    transform: rotate(var(--rotate-bar-three));
    transition: bottom 200ms 100ms, transform 100ms ease-in-out;
}

.check-icon:checked + .icon-menu > .bar--1 {
    transition: top 200ms, transform 200ms 100ms ease-in-out;
}

.check-icon:checked + .icon-menu > .bar--3 {
    transition: bottom 200ms, transform 200ms 100ms ease-in-out;
}

.check-icon:checked + .icon-menu {
    --pos-y-bar-one: calc(var(--gap) + var(--height-bar));
    --pos-y-bar-three: calc(var(--gap) + var(--height-bar));
    --scale-bar: 0;
    --rotate-bar-one: 45deg;
    --rotate-bar-three: -45deg;
}

@media only screen and (max-width: 600px) {
    .icon-menu {
        --gap: 5px;
        --height-bar: 5px;
        --pos-y-bar-one: 0;
        --pos-y-bar-three: 0;
        --scale-bar: 1;
        --rotate-bar-one: 0;
        --rotate-bar-three: 0;
        width: 25px;
        display: flex;
        flex-direction: column;
        gap: var(--gap);
        cursor: pointer;
        position: relative;
    }
    li {
        font-size: 10cqw;
    }
}
